import Link from 'next/link'
import React from 'react'
import { NavigationMenu } from 'day8-ui'

import TywMiniLogo from '../svg/TywMiniLogo'
import NavigationMenuContent from './NavigationMenuContent'
import NavbarUser from './NavbarUser'
import MobileMenu from './MobileMenu';
import { WpImageType } from '../../globalTypes'

export type NavigationMenuContentDataItem = {
  title: string
  link?: string
  buttonText: string
  description: string
  image?: {
    url: string
    alt?: string
  }
  additionalLinksTitle?: string
  additionalLinks?: {
    title: string
    link: string
  }[]
}
type NavbarProps = {
  currencyHidden?: boolean
  isNavbarTransparent?: boolean
  isNavbarFixed?: boolean
  navbarConfig: NavbarConfig
}

export type NavbarConfig = {
  label: string
  href?: string
  content?: {
    buttonText: string
    title: string
    description: string
    link?: string
    additionalLinksTitle: string
    additionalLinks: {
      title: string
      link: string
    }[]
    image: WpImageType
  }[]
}[]
export default function Navbar({ currencyHidden, isNavbarTransparent, isNavbarFixed, navbarConfig, ...props }: NavbarProps) {
  return (
    <div className='dui-sticky dui-top-0 dui-bg-white dui-py-2 dui-z-10'>
      <div className='dui-flex dui-justify-between dui-items-center dui-gap-4 dui-container dui-mx-auto'>
        <div className=''>
          <a href='/'>
            <TywMiniLogo className="dui-text-black dui-w-[64px] dui-h-[64px]" fill={"black"} />
          </a>
        </div>
        <div className='dui-hidden lg:dui-block dui-flex-grow dui-w-[100%]'>
          <div>
            <NavigationMenu
              items={navbarConfig?.map(({ label, content }) => ({ label, content: content && <NavigationMenuContent data={content} sectionTitle={label} /> }))}
            />
          </div>
        </div>
        <div className='dui-shrink-0'>
          <div className='dui-hidden lg:dui-block'>
            <NavbarUser />
          </div>
          <div className='lg:dui-hidden'>
            <MobileMenu navbarConfig={navbarConfig} />
          </div>
        </div>
      </div>
    </div>
  )
}