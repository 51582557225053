import { Button, Dialog, Divider } from "day8-ui";
import Link from "next/link";
import { IoMdClose } from "react-icons/io";
import TywMiniLogo from "../svg/TywMiniLogo";
import { NavbarConfig } from "./Navbar";
import MobileMenuItem from "./MobileMenuItem";
import { useState } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import NavbarUser from "./NavbarUser";
import { useLuckyModal } from "../../context/LuckyModalContext";


export default function MobileMenu({ navbarConfig }: { navbarConfig: NavbarConfig }) {
  const { toggle } = useLuckyModal();
  const [toggleMenu, setToggleMenu] = useState(false);
  const [activeItem, setActiveItem] = useState<number | null>(null);

  const handleItemClick = (index: number) => {
    setActiveItem(index === activeItem ? null : index); // Toggle the active item
  };
  return (
    <div>
      <Button buttonType="LINK" onClick={() => setToggleMenu(!toggleMenu)}>
        <RxHamburgerMenu className='dui-w-8 dui-h-8' />
      </Button>
      <Dialog open={toggleMenu} onOpenChange={() => setToggleMenu(!toggleMenu)} scrollable={true} dialogType="FULLSCREEN">
        <div className="dui-fixed dui-inset-0 dui-flex dui-flex-col dui-py-2 dui-px-4 dui-bg-white">
          {/* Top Section */}
          <div className="dui-flex dui-justify-between dui-items-center dui-mb-2">
            <a href="/">
              <TywMiniLogo className="dui-text-black dui-w-[64px] dui-h-[64px]" fill={"black"} />
            </a>
            <Button buttonType="LINK" onClick={() => setToggleMenu(!toggleMenu)}>
              <IoMdClose className="dui-w-8 dui-h-8" />
            </Button>
          </div>

          {/* Middle Scrollable Section */}
          <div className="dui-flex-1 dui-overflow-y-auto dui-py-2">
            {navbarConfig?.map(({ label, href, content }, index) => (
              <MobileMenuItem key={index} label={label} href={href} isActive={activeItem === index} setIsActive={() => handleItemClick(index)} data={content} />
            ))}
          </div>
          <Divider className="dui-border-style-solid dui-border-gray-300" />
          {/* Bottom Section */}
          <div className="dui-py-2">
            <NavbarUser />
            <Button className="dui-justify-center dui-mt-4 dui-w-full" onClick={toggle}>Book now</Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
