import { Button, Divider } from "day8-ui";
import Link from "next/link";
import { IoMdArrowForward } from "react-icons/io";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { NavigationMenuContentDataItem } from "./Navbar";
import Image from "next/image";
import { useState } from "react";

type MobileMenuItemProps = {
  label: string,
  href?: string,
  data?: NavigationMenuContentDataItem[],
  setIsActive: () => void,
  isActive: boolean
}
export default function MobileMenuItem({ data, label, href, setIsActive, isActive }: MobileMenuItemProps) {
  const [activeItem, setActiveItem] = useState<number | null>(0);
  return (
    <div className="dui-py-4">
      {href && (
        <a href={href}>
          <Button className="dui-flex dui-justify-between dui-w-[100%]" buttonType='LINK' onClick={setIsActive}>{label}</Button>
        </a>)}
      {!href && (
        <Button className="dui-flex dui-justify-between dui-w-[100%]" buttonType='LINK' onClick={setIsActive}>
          <span>{label}</span>
          {isActive ? <FaChevronUp className="" /> : <FaChevronDown className="" />}
        </Button>
      )}
      {data && isActive && (
        <div className="dui-mt-4 dui-border dui-border-solid dui-border-gray-200">
          {data.map((item, i) => (
            <div>
              <Button className={`${i === activeItem ? 'dui-bg-primary-50' : ''} dui-flex dui-justify-between dui-w-[100%]`} buttonType='LINK' onClick={() => setActiveItem(activeItem == i ? null : i)}>
                <span className="dui-p-4">{item.buttonText}</span>
              </Button>
              {i == activeItem && (
                <div className="dui-p-4">
                  <h3 className="dui-font-body dui-text-lg dui-mb-2">{item.title}</h3>
                  <p>{item.description}</p>
                  {item.link && (
                    <a href={item.link} >
                      <Button className="dui-mt-4 dui-mb-2" buttonType="LINK" rightIcon={<IoMdArrowForward />}>
                        Learn more
                      </Button>
                    </a>
                  )}
                  {item.image && (
                    <div className="dui-relative dui-aspect-[16/9] dui-overflow-hidden">
                      <Image className="dui-absolute dui-inset-0 dui-w-full dui-h-full dui-object-cover dui-object-center" src={item.image.url} alt={item.image?.alt || item.title} width={1024} height={768} />
                    </div>
                  )}
                  <Divider />
                  {!!item.additionalLinks?.length && (
                    <div>
                      <h3 className="dui-font-body dui-text-lg dui-my-2">{item?.additionalLinksTitle}</h3>
                      {item.additionalLinks?.map(festival => (
                        <a href={festival.link}>
                          <Button className="dui-mt-2" buttonType="LINK" rightIcon={<IoMdArrowForward />}>
                            {festival.title}
                          </Button>
                        </a>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}